<template>
  <icon-base
    v-if="expanded"
    height="21"
    width="21"
    icon="shrink"
    iconColor="none"
    iconName="Shrink"
  />
  <icon-base
    v-else
    height="21"
    width="21"
    icon="expand"
    iconColor="none"
    iconName="Expand"
  />
</template>
<script type="text/javascript">
export default {
  name: "expand-button",
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
};
</script>
